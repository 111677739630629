import parse from 'html-react-parser';

export default function OverallStatsTabContent(props){
    const weekDays = props.weekDays;
    const tabData = props.tabData;
    let listContents = "";
    //console.log(tabData);

    if(tabData.length > 0){
        weekDays.map((weekDay, index)=>{
            let activeClass = '';
            let showClass = '';
            var jobs = (tabData[index] != null && tabData[index] != undefined) ? tabData[index] : undefined;
            //console.log(jobs);
            var tableData = "";
            if(weekDay.current_day == true){   
                activeClass = 'active';
                showClass = 'show'
            }
            if(jobs != undefined){
                
                jobs.map((obj)=>{
                    
                    tableData += `<thead>
                        <tr className="head-mid">
                            <th scope="col"></th>
                            <th scope="col" className="text-center">${obj.main_task}</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>`;
                    if(obj.subTasks != undefined){
                        let count = 0;
                        tableData += `<tbody>
                        <tr>
                            <th scope="row"></th>
                            <td></td>
                            <td></td>
                        </tr>`;
                        obj.subTasks.map((subTask) => {
                            tableData += `<tr>
                                <th scope="row">${++count}</th>
                                <td>${subTask.sub_task}</td>
                                <td>${subTask.completed_by == undefined || subTask.completed_by == "" ? "--" : subTask.completed_by}</td>
                            </tr>`;
                        });
                        tableData += `</tbody>`;
                    }
                    
                });
                
                listContents+=  
                `<div id="pos-${index}" className="card tab-pane fade ${showClass} ${activeClass}" role="tabpanel" aria-labelledby="tab-${index}">
                    <div className="card-header" role="tab" id="heading-${index}">
                        <h5 className="mb-0">
                            <a data-bs-toggle="collapse" href="#pos-collapse-${index}" aria-expanded="true" aria-controls="collapse-${index}">
                                <div className="number">${weekDay.day}</div>
                                <div className="day">${weekDay.week_day}</div>
                            </a>
                        </h5>
                    </div>
                    <div id="pos-collapse-${index}" className="collapse ${showClass}" data-bs-parent="#content" role="tabpanel"
                        aria-labelledby="heading-${index}">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="text-center">S. No</th>
                                            <th scope="col" className="text-center">Tasks</th>
                                            <th scope="col">Completed By</th>
                                        </tr>
                                    </thead>
                                    
                                    ${tableData}
                                </table>
                            </div>
                        
                        </div>
                    </div>
                </div>`
            }else{
                listContents += `<div id="pos-${index}" className="card tab-pane fade ${showClass} ${activeClass}" role="tabpanel"
                aria-labelledby="tab-${index}">
                <div className="card-header" role="tab" id="heading-${index}">
                    <h5 className="mb-0">
                        <a data-bs-toggle="collapse" href="#pos-collapse-${index}" aria-expanded="true" 
                        aria-controls="collapse-${index}">
                            <div className="number">${weekDay.day}</div>
                            <div className="day">${weekDay.week_day}</div>
                        </a>
                    </h5>
                </div>
                <div id="pos-collapse-${index}" className="collapse ${showClass}" data-bs-parent="#content"
                    role="tabpanel"
                    aria-labelledby="heading-${index}">
                    <div className="card-body">
                        <div className="no-data">
                            <p>No Record Found</p>
                        </div>
                    </div>
                </div>
                </div>`
            }               
        });

        return (parse(listContents));
    } else{
        return ("No Record Found");
    }
}