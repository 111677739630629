import React, { Component } from 'react';
import StatsTab from './common/statsTab';
import Footer from './Footer';
import Header from './Header';
import {getWeekCalendar, getCurrentWeekDays} from '../services/jobService';
import { getProfile } from '../services/profileService';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class Stats extends Component{
    state = {
        calenderData: {},
        weekDays: {},
        days: 0,
        role_id : '',
        userPoints : 0,
    }
    async componentDidMount(){
        let weekData = await getCurrentWeekDays(0);
        this.setState({weekDays: weekData})

        
        let calenderData = await getWeekCalendar(0);
        this.setState({calenderData:calenderData});
        this.setState({role_id: JSON.parse(localStorage.getItem('user')).role_id})

        const previousUrls = [
            {'stats' : window.location.href}
        ];
        localStorage.setItem('previousUrls', JSON.stringify(previousUrls));
    }

    next = async(e) => {
        let days = this.state.days;

        let weekData = await getCurrentWeekDays(days+7);
        this.setState({weekDays: weekData})

        let calenderData = await getWeekCalendar(days+7);
        this.setState({calenderData:calenderData});
        this.setState({days:days+7});
    }
    previous = async(e) => {
        let days = this.state.days;

        let weekData = await getCurrentWeekDays(days-7);
        this.setState({weekDays: weekData})

        let calenderData = await getWeekCalendar(days-7);
        this.setState({calenderData:calenderData});
        this.setState({days:days-7});
    }

    render(){
        console.log(this.state.role_id);
        return(
            <>
            <Header title={'Stats'}/>
            <section className="my-stats">
                <div className="container">
                    {this.state.role_id && this.state.role_id == 2 &&
                    <div className="drop-down">
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                My Stats
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a className="dropdown-item" href="/stats">My Stats</a></li>
                                <li><a className="dropdown-item" href="/employee-stats">Employee Stats</a></li>
                                <li><a className="dropdown-item" href="/overall-stats">Overall Stats</a></li>
                            </ul>
                        </div>
                    </div>}
                    {this.state.calenderData.data && 
                        <div className=" week-calender">
                            <div className="item">
                                <div className="d-flex justify-content-center mt-4 position-relative">
                                    <div className="text-cont">
                                        <div className="owl-nav">
                                            <button type="button" role="presentation" className="owl-prev carousalBtn" onClick={(e) => this.previous()}>
                                                <span aria-label="Previous">‹</span>
                                            </button>
                                            <button type="button" role="presentation" className="owl-next carousalBtn" onClick={(e) => this.next()}>
                                                <span aria-label="Next">›</span>
                                            </button>
                                        </div> 
                                        <h2>WEEK</h2>
                                        <p>{this.state.weekDays.data && this.state.weekDays.data.weekName }</p>
                                    </div>
                                </div>
                                
                                <StatsTab calenderData={this.state.calenderData.data} weekDays={this.state.weekDays.data.weekDays}/>
                            </div> 
                        </div>
                    }
                </div>
            </section>
            <Footer />
            </>
        );
    }
}

export default Stats;