import React, { Component } from 'react';
import Footer from './Footer';
import Header from './Header';
import {getWeekCalendar, getCurrentWeekDays} from '../services/jobService';
import { getShifts, getOverallStats } from '../services/managerServices';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OverallStatsTab from './common/overallStatsTab';

class OverallStats extends Component{
    state = {
        calenderData: {},
        weekDays: {},
        shifts:{},
        days: 0,
        selectedShiftId: 1,
        role_id : '',
        userPoints : 0,
        prevoiusUrl: ""
    }

    async componentDidMount(){
        await this.getWeekWeekDays(0);

        let shiftData = await getShifts();
        this.setState({shifts: shiftData})
        
        await this.getStatsData(this.state.selectedShiftId, 0);
        this.setState({role_id: JSON.parse(localStorage.getItem('user')).role_id});

        let previous_urls = localStorage.getItem('previousUrls');
        var previousUrls = JSON.parse(previous_urls)       
        this.setState({prevoiusUrl: previousUrls[0].stats})

    }

    next = async(e) => {
        let days = this.state.days;

        await this.getWeekWeekDays(days+7);

        await this.getStatsData(this.state.selectedShiftId, days+7);
        this.setState({days:days+7});
    }
    previous = async(e) => {
        let days = this.state.days;

        await this.getWeekWeekDays(days-7);

        await this.getStatsData(this.state.selectedShiftId, days-7);
        this.setState({days:days-7});
    }

    onShiftChange = async(e) => {
        let val = e.target.value;
        await this.setState({selectedShiftId: val});
        await this.getStatsData(this.state.selectedShiftId, this.state.days);
    }

    async getStatsData(shiftId, days){
        let calenderData = await getOverallStats(shiftId, days);
        this.setState({calenderData:calenderData});
    }

    async getWeekWeekDays(days){
        let weekData = await getCurrentWeekDays(days);
        this.setState({weekDays: weekData})
    }



    render(){
        const shifts = this.state.shifts;
        var options = [];
        //console.log(shifts.data.length);
        if(shifts.data != undefined && shifts.data.length > 0){
            // this.setState({selectedShiftId: shifts.data[0].id});
            shifts.data.map((obj)=>{
                options.push(<option value={obj.id}>{obj.name}</option>)
            });
        }
        return(
            <>
            <Header title={'Overall Stats'} isBack={true} url={this.state.prevoiusUrl}/>
            <section className="my-stats overall-stats">
                <div className="container">
                    <div className="drop-down">
                        <div className="dropdown">
                            {/* <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                My Stats
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a className="dropdown-item" href="/stats">First Shift</a></li>
                                <li><a className="dropdown-item" href="/employee-stats">Second Shift</a></li>
                            </ul> */}
                            {this.state.shifts.data &&
                                <select onChange={this.onShiftChange}>
                                    {options}
                                </select>
                            }
                        </div>
                    </div>
                    {this.state.calenderData.data && 
                        <div className=" week-calender">
                            <div className="item">
                                <div className="d-flex justify-content-center mt-4 position-relative">
                                    <div className="text-cont">
                                        <div className="owl-nav">
                                            <button type="button" role="presentation" className="owl-prev carousalBtn" onClick={(e) => this.previous()}>
                                                <span aria-label="Previous">‹</span>
                                            </button>
                                            <button type="button" role="presentation" className="owl-next carousalBtn" onClick={(e) => this.next()}>
                                                <span aria-label="Next">›</span>
                                            </button>
                                        </div> 
                                        <h2>WEEK</h2>
                                        <p>{this.state.weekDays.data && this.state.weekDays.data.weekName }</p>
                                    </div>
                                </div>
                                <OverallStatsTab calenderData={this.state.calenderData.data} weekDays={this.state.weekDays.data.weekDays}/>
                                
                            </div> 
                        </div>
                    }
                </div>
            </section>
            <Footer />
            </>
        );
    }
}
export default OverallStats;